<template>
    <CFooter>
      <div>
             <span class="ms-1"
          >&copy; {{ new Date().getFullYear() }} DS Group. All rights reserved.</span
        >
      </div>
      <div class="ms-auto">
        
      </div>
    </CFooter>
  </template>
  
  <script>
  export default {
    name: 'AppFooter',
  }
  </script>
  