export const logoNegative = [
  '556 134',
  `
  <title>coreui vue logo</title>
  <g><path style="opacity:1" fill="#fdfefe" d="M -0.5,-0.5 C 97.5,-0.5 195.5,-0.5 293.5,-0.5C 293.5,90.8333 293.5,182.167 293.5,273.5C 195.5,273.5 97.5,273.5 -0.5,273.5C -0.5,182.167 -0.5,90.8333 -0.5,-0.5 Z"/></g>
<g><path style="opacity:1" fill="#2c2b29" d="M 51.5,30.5 C 56.9302,30.161 62.2636,30.661 67.5,32C 74.0297,37.0049 75.1963,43.1716 71,50.5C 68.8587,53.3208 66.0254,54.6541 62.5,54.5C 58.8333,54.5 55.1667,54.5 51.5,54.5C 51.5,46.5 51.5,38.5 51.5,30.5 Z"/></g>
<g><path style="opacity:1" fill="#2f2f2d" d="M 81.5,29.5 C 83.8568,29.3367 86.1902,29.5034 88.5,30C 93.0301,30.9033 93.6968,33.07 90.5,36.5C 87.7626,33.6971 85.4293,33.8638 83.5,37C 83.6448,37.772 83.9781,38.4387 84.5,39C 87.2442,39.8035 89.9109,40.8035 92.5,42C 95.8241,47.6891 94.4908,52.0224 88.5,55C 83.5483,56.3103 79.215,55.3103 75.5,52C 76.3041,50.2287 77.4708,48.7287 79,47.5C 81.8767,51.1641 84.71,51.3308 87.5,48C 87.3552,47.228 87.0219,46.5613 86.5,46C 75.5461,42.9789 73.8795,37.4789 81.5,29.5 Z"/></g>
<g><path style="opacity:1" fill="#2d2c2b" d="M 169.5,29.5 C 185.024,29.8503 189.858,37.1836 184,51.5C 176.031,57.3696 168.698,56.7029 162,49.5C 158.129,40.2952 160.629,33.6286 169.5,29.5 Z"/></g>
<g><path style="opacity:1" fill="#535252" d="M 51.5,30.5 C 51.5,38.5 51.5,46.5 51.5,54.5C 55.1667,54.5 58.8333,54.5 62.5,54.5C 58.7016,55.4834 54.7016,55.8168 50.5,55.5C 50.1739,46.9832 50.5073,38.6499 51.5,30.5 Z"/></g>
<g><path style="opacity:1" fill="#5e5e5d" d="M 139.5,30.5 C 139.5,38.5 139.5,46.5 139.5,54.5C 141.167,54.5 142.833,54.5 144.5,54.5C 144.335,51.4816 144.502,48.4816 145,45.5C 145.717,46.0442 146.217,46.7109 146.5,47.5C 145.527,49.9479 145.194,52.6146 145.5,55.5C 143.167,55.5 140.833,55.5 138.5,55.5C 138.174,46.9832 138.507,38.6499 139.5,30.5 Z"/></g>
<g><path style="opacity:1" fill="#2b2a29" d="M 139.5,30.5 C 143.846,30.3343 148.179,30.501 152.5,31C 157.038,32.7129 158.538,35.8795 157,40.5C 156.027,42.64 154.527,44.3067 152.5,45.5C 154.178,48.1947 155.844,50.8614 157.5,53.5C 155.393,54.4682 153.059,54.8016 150.5,54.5C 149.167,52.1667 147.833,49.8333 146.5,47.5C 146.217,46.7109 145.717,46.0442 145,45.5C 144.502,48.4816 144.335,51.4816 144.5,54.5C 142.833,54.5 141.167,54.5 139.5,54.5C 139.5,46.5 139.5,38.5 139.5,30.5 Z"/></g>
<g><path style="opacity:1" fill="#919191" d="M 191.5,30.5 C 193.607,29.5318 195.941,29.1984 198.5,29.5C 198.5,35.1667 198.5,40.8333 198.5,46.5C 197.512,41.3595 197.179,36.0262 197.5,30.5C 195.5,30.5 193.5,30.5 191.5,30.5 Z"/></g>
<g><path style="opacity:1" fill="#707070" d="M 213.5,30.5 C 211.5,30.5 209.5,30.5 207.5,30.5C 207.821,36.0262 207.488,41.3595 206.5,46.5C 206.5,40.8333 206.5,35.1667 206.5,29.5C 209.059,29.1984 211.393,29.5318 213.5,30.5 Z"/></g>
<g><path style="opacity:1" fill="#767676" d="M 230.5,30.5 C 226.833,30.5 223.167,30.5 219.5,30.5C 219.5,38.5 219.5,46.5 219.5,54.5C 221.167,54.5 222.833,54.5 224.5,54.5C 224.5,51.8333 224.5,49.1667 224.5,46.5C 226.833,46.5 229.167,46.5 231.5,46.5C 229.735,47.4614 227.735,47.7947 225.5,47.5C 225.5,50.1667 225.5,52.8333 225.5,55.5C 223.167,55.5 220.833,55.5 218.5,55.5C 218.5,46.8333 218.5,38.1667 218.5,29.5C 222.702,29.1832 226.702,29.5166 230.5,30.5 Z"/></g>
<g><path style="opacity:1" fill="#2b2a28" d="M 230.5,30.5 C 234.955,31.2898 237.289,33.9564 237.5,38.5C 237.895,43.0673 235.895,45.734 231.5,46.5C 229.167,46.5 226.833,46.5 224.5,46.5C 224.5,49.1667 224.5,51.8333 224.5,54.5C 222.833,54.5 221.167,54.5 219.5,54.5C 219.5,46.5 219.5,38.5 219.5,30.5C 223.167,30.5 226.833,30.5 230.5,30.5 Z"/></g>
<g><path style="opacity:1" fill="#f8f8f8" d="M 145.5,35.5 C 149.677,34.8416 151.177,36.5082 150,40.5C 149.617,41.056 149.117,41.3893 148.5,41.5C 147.5,41.5 146.5,41.5 145.5,41.5C 145.5,39.5 145.5,37.5 145.5,35.5 Z"/></g>
<g><path style="opacity:1" fill="#f3f3f3" d="M 170.5,35.5 C 177.655,35.1527 180.821,38.486 180,45.5C 176.324,51.5988 172.324,51.9322 168,46.5C 166.812,42.2586 167.645,38.5919 170.5,35.5 Z"/></g>
<g><path style="opacity:1" fill="#f4f4f4" d="M 57.5,35.5 C 66.2211,36.1098 68.5545,40.2765 64.5,48C 62.3225,49.2022 59.9892,49.7022 57.5,49.5C 57.5,44.8333 57.5,40.1667 57.5,35.5 Z"/></g>
<g><path style="opacity:1" fill="#f4f4f4" d="M 225.5,35.5 C 230.159,35.0146 231.492,36.848 229.5,41C 228.207,41.49 226.873,41.6567 225.5,41.5C 225.5,39.5 225.5,37.5 225.5,35.5 Z"/></g>
<g><path style="opacity:1" fill="#7b7b7b" d="M 145.5,35.5 C 145.5,37.5 145.5,39.5 145.5,41.5C 146.5,41.5 147.5,41.5 148.5,41.5C 147.432,42.4345 146.099,42.7678 144.5,42.5C 144.198,39.9407 144.532,37.6074 145.5,35.5 Z"/></g>
<g><path style="opacity:1" fill="#626262" d="M 133.5,47.5 C 133.5,45.5 133.5,43.5 133.5,41.5C 129.5,41.5 125.5,41.5 121.5,41.5C 121.5,42.8333 121.5,44.1667 121.5,45.5C 120.549,44.0813 120.216,42.4147 120.5,40.5C 125.167,40.5 129.833,40.5 134.5,40.5C 134.802,43.0593 134.468,45.3926 133.5,47.5 Z"/></g>
<g><path style="opacity:1" fill="#2a2927" d="M 191.5,30.5 C 193.5,30.5 195.5,30.5 197.5,30.5C 197.179,36.0262 197.512,41.3595 198.5,46.5C 199.128,49.9549 201.128,51.1215 204.5,50C 205.823,49.1841 206.489,48.0174 206.5,46.5C 207.488,41.3595 207.821,36.0262 207.5,30.5C 209.5,30.5 211.5,30.5 213.5,30.5C 213.666,37.175 213.5,43.8417 213,50.5C 210.505,54.4541 206.839,56.1208 202,55.5C 197.408,56.2041 194.075,54.5375 192,50.5C 191.5,43.8417 191.334,37.175 191.5,30.5 Z"/></g>
<g><path style="opacity:1" fill="#2d2c2b" d="M 121.5,45.5 C 121.5,44.1667 121.5,42.8333 121.5,41.5C 125.5,41.5 129.5,41.5 133.5,41.5C 133.5,43.5 133.5,45.5 133.5,47.5C 132.752,51.417 130.418,53.917 126.5,55C 111.329,56.8229 105.829,50.3229 110,35.5C 117.245,27.7514 124.745,27.5847 132.5,35C 131.107,36.3631 129.441,37.1964 127.5,37.5C 118.766,33.2769 114.599,35.9435 115,45.5C 117.506,50.6226 121.339,51.7892 126.5,49C 127.337,47.9887 127.67,46.8221 127.5,45.5C 125.5,45.5 123.5,45.5 121.5,45.5 Z"/></g>
<g><path style="opacity:1" fill="#696867" d="M 157.5,53.5 C 158.043,53.56 158.376,53.8933 158.5,54.5C 155.691,55.8003 153.024,55.8003 150.5,54.5C 153.059,54.8016 155.393,54.4682 157.5,53.5 Z"/></g>
<g><path style="opacity:1" fill="#e1f2e8" d="M 50.5,76.5 C 77.003,75.1707 103.67,75.1707 130.5,76.5C 130.043,77.2975 129.376,77.6309 128.5,77.5C 102.672,76.5022 76.672,76.1689 50.5,76.5 Z"/></g>
<g><path style="opacity:1" fill="#049746" d="M 164.5,76.5 C 200.639,77.5166 223.806,95.5166 234,130.5C 243.211,177.908 227.378,214.408 186.5,240C 176.738,245.396 166.404,248.729 155.5,250C 180.049,237.738 191.216,217.905 189,190.5C 187.415,183.998 184.748,177.998 181,172.5C 171.195,161.941 161.195,151.607 151,141.5C 141.343,129.863 137.343,116.53 139,101.5C 143.443,88.8888 151.943,80.5554 164.5,76.5 Z"/></g>
<g><path style="opacity:1" fill="#059747" d="M 50.5,76.5 C 76.672,76.1689 102.672,76.5022 128.5,77.5C 95.0893,89.9708 80.9226,113.971 86,149.5C 88.8115,161.461 94.4782,171.794 103,180.5C 112,188.833 121,197.167 130,205.5C 140.884,220.148 139.718,233.648 126.5,246C 123.052,247.927 119.385,249.261 115.5,250C 93.8728,251.432 72.2061,251.932 50.5,251.5C 50.5,193.167 50.5,134.833 50.5,76.5 Z"/></g>
`,
]
